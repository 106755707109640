
import {  Swiper } from "swiper";
import { Navigation, Pagination, Autoplay} from 'swiper/modules';

import gsap from '../../js/gsap/gsap.min.js';
import { ScrollTrigger } from '../../js/gsap/ScrollTrigger.min.js';
import { DrawSVGPlugin  } from '../../js/gsap/DrawSVGPlugin.min.js';
import { ScrollToPlugin  } from '../../js/gsap/ScrollToPlugin.min.js';
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin, ScrollToPlugin );


if ( document.getElementById("mask")) {
document.querySelectorAll('.has-mask-fill').forEach(function(element) {
  var words = element.textContent;
  var total = words;
  element.innerHTML = '';
  var span = document.createElement('span');
  span.textContent = words;
  element.appendChild(span);
});



var hasMaskFill = gsap.utils.toArray('.has-mask-fill');			
hasMaskFill.forEach(function(hMaskFill) {				
  var spanFillMask = hMaskFill.querySelectorAll("span");
  gsap.to(spanFillMask, { 					
    scrollTrigger: {
      trigger: hMaskFill,
      start: "top 85%",
      end: () => `+=${hMaskFill.offsetHeight * 2}`,
      scrub: 1,
      markers: true,
    },
    duration: 1, 
    backgroundSize:"200% 100%", 
    stagger:0.5,  
    ease:Linear.easeNone,
    
  });
}); 
}
if ( window.location.pathname == '/' ){

const menu = Array.from(document.querySelectorAll('.c-teams-archive_card'))
.map(element => element.getAttribute('data-id'));
new Swiper('#team', {
  modules: [ Pagination, Navigation],
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<a class="w-auto btn fw-bold fs-5 text-decoration-none fw-bold fs-5 rounded-pill px-3 py-2 ' + className + '">' + (menu[index]) + '</a>';
    },
  },
  navigation: {
    nextEl: '.team-swiper-button-next',
    prevEl: '.team-swiper-button-prev',
  },
  loop: true,
  slideToClickedSlide: true,
  breakpoints: {
     
    320: {
      slidesPerView: 1,
      spaceBetween: 20,
      
    },
    640: {
      slidesPerView: 3.1,
      spaceBetween: 10,
    }
  },

  on: {
    transitionStart: function(){
      
      console.log('start');
    },
    change: function(){
      console.log('changed');
      
    },
    transitionEnd: function(){
      
     console.log('end');

    },
  
  }

});
  
new Swiper('#matches', {
  modules: [Navigation],
  breakpoints: {
  
    320: {
      slidesPerView: 1,
      spaceBetween: 20
    },
    640: {
      slidesPerView: 3,
      spaceBetween: 20
    },
    1300: {
      slidesPerView: 5,
      spaceBetween: 20
    }
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});



}

const swiper2 = new Swiper('.sponsors-swiper', {
  modules: [ Autoplay ],
  breakpoints: {
  
    320: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    640: {
      slidesPerView: 4,
      spaceBetween: 10,
    }
  },
 

  loop: true,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
});

//data-no
var elements = document.querySelectorAll('.c-teams-archive_card');
if (elements.length > 0) {
  var myarray = [];
  elements.forEach(function (element) {
    myarray.push(element.getAttribute("data-no"));
  });
}

document.addEventListener('DOMContentLoaded', function() {
const header = document.getElementById("wrapper-navbar");
header.classList.add("sticky");

function addClass() {
  header.classList.add('scrolling');
}
function removeClass() {
  header.classList.remove('scrolling')
}
ScrollTrigger.addEventListener('scrollStart', addClass)
ScrollTrigger.addEventListener('scrollEnd', removeClass)

let mm = gsap.matchMedia();
mm.add("(min-width: 992px)", () => {
const showAnim = gsap.from(header, { 
  yPercent: -100,
  paused: true,
  duration: 0.8,
  delay: 1,
}).progress(1);

ScrollTrigger.create({
  start: 400,
  // end: 99999,
  end: () => ScrollTrigger.maxScroll(window) - 2,
  onUpdate: (self) => {
    self.direction === -1 ? showAnim.play() : showAnim.reverse()
  },
  onLeave: () => showAnim.play()
});




    let timer;
    let prevLink;
    let prevContent;

    function showMenu(target, link) {
      const element = document.getElementById(target);
      if (element) {
        gsap.to(element, { opacity: 1, display: 'block', duration: 0.3,  ease: "power4.out",});
        gsap.to(link, { backgroundColor: '#fff', color: '#72100D',});
      }
    }

    function hideMenu(target, link) {
      const element = document.getElementById(target);
      if (element) {
        timer = setTimeout(() => {
          gsap.to(element, { opacity: 0, display: 'none', duration: 0.3, ease: "power4.out", });
           gsap.to(link, { backgroundColor: '', color: '', });
        }, 50); // Adjust the delay as needed
      }
    }

    function clearTimer() {
      clearTimeout(timer);
    }

    document.querySelectorAll('#main-menu .menu-item a, .menu-content').forEach(item => {
      
      item.addEventListener('mouseenter', function() {
        const target = this.getAttribute('data-target');
        const currentLink = this;
        showMenu(target, currentLink);
        prevLink = currentLink;
        prevContent = document.getElementById(target);
      });

      item.addEventListener('mouseleave', function() {
        const target = this.getAttribute('data-target');
        const currentLink = this;
        hideMenu(target, currentLink);
      });

    });

    document.querySelectorAll('.menu-content').forEach(item => {
      item.addEventListener('mouseenter', clearTimer);
      item.addEventListener('mouseleave', function() {
        const mtarget = this.id;
        let mitem = document.querySelector(`[data-target=${CSS.escape(mtarget)}]`);
        gsap.to(mitem, { backgroundColor: '', color: '', });
        gsap.to(prevLink, { backgroundColor: '', color: '', });
        gsap.to(this, { opacity: 0, display: 'none', duration: 0.3,  ease: "power4.out",});
        
        
      });
    });

  });

});


if(document.getElementById("honorWrap")) {
  let hlinks = gsap.utils.toArray(".honor-tabs a");
  let previousClickedDataIndex = null;
  
  hlinks.forEach((ha, index) => {
    let dataIndex = ha.getAttribute("data-id");
    let tabIndex = index;
  
    
    ha.addEventListener("click", (e) => {
      // Log the clicked element and data-id
      console.log("Clicked element:", ha);
      console.log("data-id:", dataIndex);
      console.log("tabindex:", tabIndex);
      // Log the previous clicked data-id
  
  var sheetContainers = document.querySelectorAll('.sheet-container');
    
  sheetContainers.forEach(function(sheetContainer) {
    sheetContainer.classList.remove('active');
  });
  
  let newSheet =  document.querySelector('.sheet'+dataIndex+'');
  console.log(newSheet);
  newSheet.classList.add("active");
  
  let seniorSheet = document.querySelector('.sheet'+dataIndex+'-senior');
  if (seniorSheet !== null) {
    seniorSheet.classList.add("active");
  }
  
      if (previousClickedDataIndex !== null) {
        console.log("Previous clicked data-id:", previousClickedDataIndex);
  
        // Reset the translation for the previous clicked tab
        let prevCounterElements = document.querySelectorAll(".counter[data-id='" + previousClickedDataIndex + "']");
        prevCounterElements.forEach((prevCounter) => {
          let lastNumber = prevCounter.querySelectorAll(".slidenotwo");
          // let lastTwoNumbers = prevCounter.querySelectorAll(".gg[data-text='2'], .gg[data-text='1']");
          // gsap.to(lastTwoNumbers, {
          //   yPercent: 0,
          //   ease: Power1.easeOut,
          // });
          gsap.to(lastNumber, {
          yPercent: 0, 
          ease: Power1.easeIn,
        });
        });
      }
  
      // Update the previous clicked data-id
      previousClickedDataIndex = dataIndex;
      let lastNumber = document.querySelectorAll(".slidenotwo");
        gsap.to(lastNumber, {
          yPercent: -tabIndex * 10, // Adjust the translation value as needed
          ease: Power1.easeIn,
        });
  
  if(tabIndex > 3 ) {
    let lastNumberTwo = document.querySelectorAll(".slideno");
        gsap.to(lastNumberTwo, {
          delay: .1,
          yPercent: -50, // Adjust the translation value as needed
          ease: Power1.easeIn,
        });
  }
  if(tabIndex <= 3 ) {
    let lastNumberTwo = document.querySelectorAll(".slideno");
        gsap.to(lastNumberTwo, {
          delay: .1,
          yPercent: 0, // Adjust the translation value as needed
          ease: Power1.easeIn,
        });
  }
        
  
      // Update elements with class .counter that match the data-id
      let counterElements = document.querySelectorAll(".counter[data-id='" + dataIndex + "']");
      counterElements.forEach((counter) => {
        console.log("counter is:", counter);
       
      });
    });
  });
  
  
  
  document.addEventListener("DOMContentLoaded", function() {
     
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4 && xhr.status === 200) {
          var data = JSON.parse(xhr.responseText);
          displaySheetData(data.sheets);
        }
      };
      xhr.open("GET", "https://subiacosoccer.asn.au/wp-content/themes/subiacofc/data/new.json", true);
      // xhr.open("GET", "https://script.googleusercontent.com/a/macros/loadedcommunications.com.au/echo?user_content_key=anzObIItiCcInhZm5LxeNVId_SSQo8zOIMRM-ptAiREDZriAejqlXCukoJ2zsl3QZbpv7s7HLMtj9sBc_eDJ3FMywUwlEphWOJmA1Yb3SEsKFZqtv3DaNYcMrmhZHmUMi80zadyHLKB3FAgUeT81wCdwVhfTGjM_PhHF4VODybziNftnwcuadIdJZjg3LmP9aA1o15GpwJeJZGteIvIrcGuUXqdRv1B5li_Ew-P8R0lPVbpUexmRayIF6KIPlRj8zS_yBXA_p2P1Bkd7XNQxnbh5z1oqHZbo&lib=MpGXdQDTadk4uBCa-1ghHlOak-iN5Ucx5", true); 
      xhr.send();
    });
  
    function displaySheetData(sheets) {
      var contentContainer = document.getElementById("content-container");
      sheets.forEach(function(sheet, index) {
      var sheetContainer = document.createElement("div");
      sheetContainer.className = "sheet-container sheet"+sheet.sheetName.toLowerCase().replace(/\s+/g, '-');
      sheetContainer.id = "no-more-tables";
      
      var title = document.createElement("h2");
      title.textContent = sheet.sheetName + " - " + 'Team Awards';
      sheetContainer.appendChild(title);
  
      var table = document.createElement("table");
      var headerRow = table.insertRow(0);
  
      const columnKeys = Object.keys(sheet.data[0]).filter(key => key !== 'Category'); 
  
       columnKeys.forEach(key => {
        var th = document.createElement("th");
        th.textContent = key;
        headerRow.appendChild(th);
      });
  
      // Create table rows
      sheet.data.forEach(rowData => {
        const row = table.insertRow();
        columnKeys.forEach((key, colIndex) => {
          const cell = row.insertCell();
          cell.textContent = rowData[key];
          cell.setAttribute('data-title', table.rows[0].cells[colIndex].textContent);
        });
      });
       
  
        sheetContainer.appendChild(table);
        contentContainer.appendChild(sheetContainer);
        if (index  >= sheets.length - 2) {
          sheetContainer.classList.add('active');
        }
        
      });
  
    }
  
  }


if ( document.getElementById("leagueTable")) {
  document.querySelectorAll(".league-table__dd li a").forEach(function (link) {
    link.addEventListener("click", function () {
      var txt = document.querySelector('.dd-btn').textContent;
      var btnFirstChild = document.querySelector(".dd-btn");
      if (btnFirstChild) {
        btnFirstChild.innerHTML = this.textContent;
      }

      var id = this.id;
  //     console.log(id);
  //     var links = document.querySelectorAll('.league-table__dd li a');
  // for (var i = 0; i < links.length; i++) {
  //   this.textContent = txt;
  // }
  
  document.querySelectorAll(".round-card-holder").forEach(function (card) {
    card.classList.add('d-none');
    var cardId = card.id;
    if (cardId === id) {
      card.classList.remove('d-none');
    }
  });



    });
  });
}


if (document.querySelector(".team-swiper-button-prev-desktop")) {
document.querySelector(".team-swiper-button-prev-desktop").addEventListener("click", function () {
  document.querySelector(".team-swiper-button-prev").click();
  return false;
});
}




